import React from 'react'
import ContactMeSection from '../Components/ContactMe';
import { homeObjThree } from '../Components/Sections/Data';
import Footer from '../Components/Footer';
import Sections from '../Components/Sections';
const ContactMePage = () => {
  return (
    <>
      <ContactMeSection />
      <Footer />
    </>
  )
}

export default ContactMePage
